import React from 'react'
import { ChoiceItem } from '../choice.item'

import { Container } from '../container'
import { Question } from '../question'

export const TrueFalse = ({ value, onCheckChange, checked, ...props }) => {
  const handleCheck = (value) => () => onCheckChange && onCheckChange(value)

  return (
    <Container>
      <Question value={value} />
      <ChoiceItem
        label='True'
        onClick={handleCheck(true)}
        selected={checked === true}
      />
      <ChoiceItem
        label='False'
        onClick={handleCheck(false)}
        selected={checked === false}
      />
    </Container>
  )
}
