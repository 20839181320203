import { Checkbox, CircularProgress, withStyles } from '@material-ui/core'
import React from 'react'

export const ImageBlock = withStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    cursor: 'pointer',
    border: `solid 0px ${theme.palette.primary.main}`,
    borderWidth: ({ selected }) => (selected ? 6 : 0),
    transition: 'all 0.125s',
    '&:before': {
      display: 'block',
      paddingTop: '100%',
      content: "''"
    }
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.grey[400]
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    '&:hover': {
      objectFit: 'contain'
    }
  },
  checkbox: {
    position: 'absolute',
    top: 4,
    right: 4
  }
}))(({ file, classes, loading, selected, ...props }) => {
  return (
    <div className={classes.root} {...props}>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress color='inherit' />
        </div>
      ) : (
        file && (
          <React.Fragment>
            <img
              className={classes.image}
              src={file.content.thumbnail || file.content.original}
            />
            <Checkbox
              className={classes.checkbox}
              checked={Boolean(selected)}
              color='primary'
            />
          </React.Fragment>
        )
      )}
    </div>
  )
})
