import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Tab,
  Tabs
} from '@material-ui/core'
import React from 'react'
import { AppBarTabs } from './index.appbar'
import { Context, connect } from './index.context'
import { UploadButton } from './index.upload.button'
import { TabBrowse } from './tab.browse'
import { TabPublic } from './tab.public'

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='down' ref={ref} {...props} />
))

export const ImagePicker = connect()((props) => {
  const store = React.useContext(Context)
  const [state, setState] = store.state
  const [open, setOpen] = React.useState(false)

  const handleOpen = (o) => () => setOpen(o)
  const handleChangeTab = (e, n) => setState((s) => ({ ...s, tab: n }))
  const handleConfirm = () => {
    store.onConfirm(state.selected)
    handleOpen(false)();
  }

  return store.user && store.config ? (
    <React.Fragment>
      {props.children &&
        React.cloneElement(props.children, {
          onClick: handleOpen(!open)
        })}
      <Dialog
        fullWidth
        maxWidth='lg'
        open={open}
        TransitionComponent={Transition}
        onClose={handleOpen(false)}
      >
        <DialogTitle>Image Picker</DialogTitle>
        <AppBarTabs>
          <Tabs value={state.tab} onChange={handleChangeTab}>
            <Tab label='Browse' />
            <Tab label='Public' />
          </Tabs>
        </AppBarTabs>
        <DialogContent dividers>
          {state.tab === 0 && <TabBrowse />}
          {state.tab === 1 && <TabPublic />}
        </DialogContent>
        <DialogActions>
          <UploadButton />
          <Box flex={1} />
          {typeof store.onConfirm === 'function' && (
            <Button
              disabled={!Boolean(state.selected.length)}
              color='primary'
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          )}
          <Button onClick={handleOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {props.children &&
        React.cloneElement(props.children, {
          disabled: true
        })}
    </React.Fragment>
  )
})
