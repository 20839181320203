import React, { useEffect, useState } from 'react'
import { List, Typography, withStyles } from '@material-ui/core'

import { shuffle } from '../shuffle'
import { ChoiceItem } from '../choice.item'
import { Question } from '../question'
import { Container } from '../container'

export const Multiple = withStyles((theme) => ({
  question: {
    display: 'flex'
  }
}))(({ classes, value, checked, onCheckChange, ...props }) => {
  const [options,setOptions] = useState([]);
  const handleCheck = (id) => () => onCheckChange && onCheckChange(id)

  useEffect(()=>{
    if(value.options){
      setOptions(shuffle(value.options));
    }
  }, [value])

  return (
    <Container>
      <Typography className={classes.question} variant='body1' component='div'>
        {typeof props.number === 'number' ? (
          <div>{props.number + '.'}&nbsp;</div>
        ) : (
          ''
        )}
        <Question value={value} />
      </Typography>
      <List>
        {Array.isArray(value.options) &&
          options.map((option) => (
            <ChoiceItem
              value={option}
              selected={option.key === checked}
              onClick={handleCheck(option.key)}
              key={option.key}
            />
          ))}
      </List>
    </Container>
  )
})
