import { faFolderOpen, faSpinner } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@material-ui/core'
import React, { useContext } from 'react'
import { Context } from './index.context'
import axios from 'axios'
import firebase from 'firebase/app';
import 'firebase/firestore';

const genKey = () => Math.floor(Math.random() * 1000000)

const uploadURL = 'https://nas.phra.in/upload.php'
const Uploading = async (file, { user, callback }) => {
  const data = new FormData()
  data.append('upload', file)
  data.append('uid', user.uid)
  const config = {
    onUploadProgress: (progressEvent) => {
      let progress = (progressEvent.loaded / progressEvent.total) * 100
      progress = Math.floor(progress * 0.75)
      if (typeof callback === 'function') {
        callback(progress)
      }
    }
  }
  const result = await axios.post(uploadURL, data, config);
  const dbTimestamp = firebase.firestore.FieldValue.serverTimestamp;
  const query = await firebase
    .firestore()
    .collection('documents')
    .where('user', '==', user.uid)
    .where('md5', '==', result.data.md5)
    .get()
  if (query.docs.length) {
    await firebase
      .firestore()
      .collection('documents')
      .doc(query.docs[0].id)
      .update({ dateedit: dbTimestamp() })
    return query.docs[0].id
  } else {
    const { md5, ...content } = result.data;
    const imageData = {
      content,
      md5,
      user: user.uid,
      dateedit: dbTimestamp(),
      visibility: 'private',
      type: 'image',
      parent: 0
    }
    const addResult = await firebase
      .firestore()
      .collection('documents')
      .add({
        ...imageData,
        datecreate: dbTimestamp()
      })
    return addResult.id
  }
}

const defaultUpload = {
  uploading: false,
  file: null,
  progress: 0
}
export const UploadButton = (props) => {
  const store = useContext(Context)
  const [state, setState] = store.state
  const [upload, setUpload] = React.useState({ ...defaultUpload })

  const key = genKey()

  const handleUploadFiles = async ({ target }) => {
    if (target.files.length) {
      const file = [...target.files][0]
      setUpload((s) => ({
        ...s,
        uploading: true,
        file,
        progress: 0
      }))
      const config = {
        user: store.user,
        callback: (progress) => setUpload((s) => ({ ...s, progress })),
        firebase: store.firebase
      }
      await Uploading(file, config);
      setUpload({ ...defaultUpload })
    }
  }

  return (
    <React.Fragment>
      <input
        type='file'
        id={`upload-${key}`}
        hidden
        accept='image/*'
        onChange={handleUploadFiles}
      />
      <label htmlFor={`upload-${key}`}>
        <Button
          component='span'
          variant='outlined'
          startIcon={
            <FontAwesomeIcon
              icon={upload.uploading ? faSpinner : faFolderOpen}
              pulse={upload.uploading}
            />
          }
          disabled={upload.uploading}
        >
          {upload.uploading ? `Uploading ${upload.progress}%` : 'Upload'}
        </Button>
      </label>
    </React.Fragment>
  )
}
