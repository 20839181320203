import React, { useEffect, useState } from 'react'
import { Editor, EditorState, convertFromRaw } from 'draft-js'

export const ViewParagraph = ({ value, ...props }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )

  useEffect(() => {
    if (value && Array.isArray(value.blocks)) {
      const contentState = convertFromRaw(value)
      const cacheState = EditorState.createWithContent(contentState)
      setEditorState(cacheState)
    } else {
      setEditorState(() => EditorState.createEmpty())
    }
  }, [value])

  return <Editor editorState={editorState} readOnly />
}
