import React from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles
} from '@material-ui/core'
import { ViewParagraph } from './view.paragraph'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/pro-regular-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'

import { ImageDisplay } from './image.display'

const Primary = ({ value, ...props }) => {
  if(!value || !value.type){
    return props.label || 'Empty content'
  } else {
    switch (value.type) {
      case 'paragraph':
        return <ViewParagraph value={value.value} />
      case 'image':
        if (value.data && value.data.thumbnail) {
          return <ImageDisplay image={value.data} />
        }
      default:
        return 'Empty content'
    }
  }
}

export const ChoiceItem = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      border: `solid 1px ${theme.palette.primary.dark}`,
      '& .lms-MuiListItemIcon-root': {
        color: theme.palette.primary.contrastText
      }
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  listitemicon: {
    minWidth: 38
  }
}))(({ classes, value, ...props }) => {
  return (
    <ListItem className={classes.root} button {...props}>
      <ListItemIcon className={classes.listitemicon}>
        <FontAwesomeIcon
          size='lg'
          icon={props.selected ? faCheckCircle : faCircle}
        />
      </ListItemIcon>
      <ListItemText primary={<Primary value={value} {...props} />} />
    </ListItem>
  )
})
