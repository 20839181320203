import { withStyles } from '@material-ui/core'
import React from 'react'

export const Container = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2)
    }
  }
}))(({ classes, ...props }) => <div className={classes.root} {...props} />)
