import React, { createContext } from 'react'
import firebase from 'firebase/app';
import 'firebase/firestore';

export const Context = createContext({})
export const ContextProvider = ({ children, ...props }) => {
  const [state, setState] = React.useState({
    tab: 0,
    docs: [],
    selected: [],
  });
  const [fb,setFB] = React.useState(null);

  const store = {
    ...props,
    state: [state, setState],
    firebase: fb,
  }

  React.useEffect(()=>{
    if(store.config){
      if(!firebase.apps.length){
        firebase.initializeApp(store.config);
        setFB(firebase);
      }
    }
  }, [store.config])

  return <Context.Provider value={store}>{children}</Context.Provider>
}
export const connect = (config) => (Comp) => (props) =>
  (
    <ContextProvider config={config} {...props}>
      <Comp {...props} />
    </ContextProvider>
  )
