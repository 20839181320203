import React from 'react';
import { ImageDisplay } from './image.display';
import { ViewParagraph } from './view.paragraph';

export const Question = ({ value }) => {
  switch (value.qtype) {
    case 'image':
      return <ImageDisplay image={value.question} />
    default:
      return <ViewParagraph value={value.question} />
  }
}