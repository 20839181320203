import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import {
  StylesProvider,
  createGenerateClassName
} from '@material-ui/core/styles'

import { Multiple } from './multiple'
import { TrueFalse } from './TrueFalse'
import { Matching } from './matching'

const generateClassName = createGenerateClassName({
  seed: 'lms'
})

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 704,
      lg: 960,
      xl: 1280
    }
  },
  palette: {
    secondary: { main: '#C73D5D' },
    error: { main: '#DC4E34' },
    success: { main: '#6E953B' },
    warning: { main: '#EEA320' },
    primary: { main: '#3C77AE' },
    common: {
      black: '#2C261E'
    },
    background: {
      gradient:
        'linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)'
    },
    duotone: {
      '--fa-primary-color': '#3C77AE',
      '--fa-secondary-color': '#EEA320'
    }
  },
  typography: {
    h1: {
      fontSize: 72,
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`
    },
    h2: {
      fontSize: 60,
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`
    },
    h3: {
      fontSize: 48,
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`
    },
    h4: {
      fontSize: 36,
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`
    },
    h5: {
      fontSize: 30,
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`
    },
    h6: {
      fontSize: 24,
      fontWeight: 'bold',
      fontFamily: `"Kanit", "Roboto", "Helvetica", "Arial", sans-serif`
    }
  },
  shape: {
    sidebarWidth: 270
  }
})

export const QuizDisplay = ({ value, ...props }) => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        {value.type === 'multiple' && <Multiple value={value} {...props} />}
        {value.type === 'truefalse' && <TrueFalse value={value} {...props} />}
        {value.type === 'matching' && <Matching value={value} {...props} />}
      </ThemeProvider>
    </StylesProvider>
  )
}
